import * as React from 'react';
import { gql } from 'urql';
import { CatalogLayout } from 'components/CatalogLayout';
import { BackButton } from 'components/BackButton';
import { PageError } from 'components/PageError';
import { Loader } from 'components/Loader';
import { AuthRequired } from 'components/AuthRequired';
import { useMyDepositPageQuery } from 'generated/graphql';
import { Price } from 'components/Price';
import { formatDay } from 'utils';

const getLabel = ({ orderId, value }: { orderId: number | null; value: string }) => {
  if (orderId) {
    return `Заказ №${orderId}`;
  }

  if (value && parseFloat(value) > 0) {
    return 'Пополнение';
  }

  return 'Заказ';
};

const tHeadItem = "pb-4 text-gray-800 font-thin";
const tHeadItemRight = `text-right ${tHeadItem}`;
const tHeadItemLeft = `text-left ${tHeadItem}`;

const Content = () => {

  const [res, refetchQuery] = useMyDepositPageQuery({
    requestPolicy: 'cache-and-network',
  });

  if (res.error) {
    const retry = () => refetchQuery({ requestPolicy: 'network-only' });
    return <PageError error={res.error} retry={retry} />;
  }

  if (res.fetching || !res.data) {
    return <Loader className="flex justify-center" />;
  }

  if (!res.data.viewer.user) {
    return <AuthRequired />;
  }

  const { depositTransactions, depositBalance } = res.data.viewer.user;

  return (
    <>
      <span className='mb-6'>Текущий баланс: <Price value={depositBalance} className='inline-block' /></span>
      {depositTransactions.length > 0 ? (
        <table className='mb-6'>
          <thead>
            <tr>
              <th className={tHeadItemLeft}>Событие</th>
              <th className={tHeadItemRight}>Дата</th>
              <th className={tHeadItemRight}>Сумма</th>
              <th className={tHeadItemRight}>Осталось</th>
            </tr>
          </thead>
          <tbody>
            {depositTransactions.map((item) => (
              <tr key={item.id}>
                <td className='pb-2'>{getLabel({ orderId: item.orderId || null, value: item.value })}</td>
                <td className='text-right pb-2'>{formatDay(item.createdAt)}</td>
                <td className='text-right pb-2'>
                  <Price value={Number(item.value)} />
                </td>
                <td className='text-right pb-2'>
                  <Price value={Number(item.stepValue) || 200} />
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      ) : (
        <div>История депозита не найдена.</div>
      )}
    </>
  )
};

const MyDeposit = () => (
  <CatalogLayout>
    <BackButton className="text-2xl font-bold mt-7 mb-11">Депозит</BackButton>
    <Content />
  </CatalogLayout>
);

gql`
  query MyDepositPageQuery {
    viewer {
      id
      user {
        id
        depositBalance
        depositTransactions {
          id
          createdAt
          value
          stepValue
          orderId
        }
      }
    }
  }
`

export default MyDeposit;
